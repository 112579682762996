// scroll-effect.js
/*document.addEventListener('DOMContentLoaded', (event) => {
    const firstColor = '#e66465'; // Pink
    const secondColor = '#ffd700'; // Yellow
    let isScrolling = false;
  
    function handleScroll() {
      if (!isScrolling) {
        document.body.style.background = secondColor;
      }
      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(function() {
        document.body.style.background = firstColor;
        isScrolling = false;
      }, 150);
    }
  
    window.addEventListener('scroll', handleScroll);
});*/
document.addEventListener('DOMContentLoaded', (event) => {
    const blurElement = document.querySelector('.background-blur');
    let isScrolling;

    // Function to add blur when scrolling
    function addBlur() {
        blurElement.classList.add('blur'); // Add a class to apply blur
    }

    // Function to remove blur when not scrolling
    function removeBlur() {
        blurElement.classList.remove('blur'); // Remove the class to remove blur
    }

    // Listen for scroll events
    window.addEventListener('scroll', () => {
        // Clear our timeout throughout the scroll
        window.clearTimeout(isScrolling);

        // Add blur effect when scrolling
        addBlur();

        // Set a timeout to run after scrolling ends
        isScrolling = setTimeout(removeBlur, 200); // Adjust this value as needed for smoothness
    });
});
